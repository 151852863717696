<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <el-checkbox
        :disabled="field.meta && field.meta.disabled ? field.meta.disabled : false"
        v-model="value"
    >
      {{ value ? $t('system.yes') : $t('system.no') }}
    </el-checkbox>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName]"
    >
      <span>{{ $t(backend_errors[field.dbName][0]) }}</span>
    </small>
  </base-form-item>
</template>

<script>
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  name: 'CreateOrUpdate_checkbox',
  mixins: [abstractForm],
  components: {BaseFormItem},
  mounted() {
    this.value = !!this.value;
  },
};
</script>

<style scoped>

</style>
